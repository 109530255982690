<div class="common-paginator-wrapper" *ngIf="total > limit">
    <div class="item jump" (click)="gotoBack()" [class.disabled]="activePage === 0">Previous</div>
    <div class="item jump" *ngIf="pages[0] > 0" (click)="gotoFirst()">First</div>
    <div class="item" *ngFor="let page of pages;let i = index" [class.active]="page === activePage"
        (click)="goto(page)">
        {{ page+1 }}
    </div>
    <div class="item jump" *ngIf="pages[pages.length-1] < totalPages-1" (click)="gotoLast()">Last</div>
    <div class="item jump" (click)="gotoNext()" [class.disabled]="activePage === pages.length-1">Next</div>
</div>
