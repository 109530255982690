<div class="user-home-container" id="main-view" #viewDOM>
    <div class="sidebar-warapper">
        <div class="sidebar">
            <div class="brand-name">
                <img class="large" src="/assets/brand-logo.png">
                <img class="small" src="/assets/brand-logo_mini.png">
            </div>
            <div class="brand-name">
                <!-- <img class="large" src="/assets/dummy-profile-pic.jpg"> -->
                <!-- <img class="small" src="/assets/dummy-profile-pic.jpg"> -->
            </div>
            <div class="nav-list">
                <div class="item spacer"></div>
                <div class="item" *ngFor="let item of navList" [class.active]="item.route === activeSection">
                   
                    <div class="item-main" *ngIf="availableFeatures.OneTrack!=false && item.name=='OneTrack Lite'" [routerLink]="'/home/'+item.route"
                        [class.active]="item.route === activePage || (item.route === activeSection && !item.children)" (click)="onclick1()">
                        <img [src]="'assets/icons/nav-bar/'+item.icon" class="def">
                        <img [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus">
                        <span class="label">{{ item.name }}</span>
                    </div>
                    <div class="item-main" *ngIf="availableFeatures.oneTrackAdvanced!=false && item.name=='OneTrack Advance'" [routerLink]="'/home/'+item.route"
                    [class.active]="item.route === activePage || (item.route === activeSection && !item.children)" (click)="onclick1()">
                    <img [src]="'assets/icons/nav-bar/'+item.icon" class="def">
                    <img [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus" >
                    <span class="label">{{ item.name }}</span>
                </div>
                <div>
                <div class="item-main" *ngIf="availableFeatures.oneChange!=false && item.name=='OneChange'" [routerLink]="'/home/'+item.route"
                    [class.active]="item.route === activePage || (item.route === activeSection && item.children)" (click)="onclick()">
                    <img [src]="'assets/icons/nav-bar/'+item.icon" class="def">
                    <img [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus">
                    <span class="label">{{ item.name }}</span>
                </div>
                    <div class="sub-menu bewel" *ngIf="item.children"
                    >
                        <ng-container *ngFor="let sub of item.children">
                            <div *ngIf="visible" class="item-sub bewel"
                                [routerLink]="'/home/'+item.route+'/'+sub.route"
                                >
                                 <span class="label" id="subname">{{ sub.name }}</span>
                            </div>
                            <!-- <div class="item bewel" [routerLink]="'/home/'+item.route+'/'+sub.route"
                            *ngIf="sub.name !='oneChange'">
                                <span class="label" >{{ sub.name }}</span>
                            </div> -->
                        </ng-container>
                        <div class="bewel"></div>
                    </div>
                </div>
                    <ng-container *ngIf="!item.children">
                        <div class="bewel"></div>
                    </ng-container>
                </div>
                <div class="item spacer"></div>
            </div>
        </div>
    </div>
    <div class="body-container" *ngIf="user">
        <div class="view-wrapper" >
            <div class="user-main-panel" id="menuHeaderpanel" style="background-color: none;">
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;"  *ngIf="this.onetrack==true">OneTrack Lite</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;"  *ngIf="this.onetrackAdvance==true">OneTrack Advance</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;" *ngIf="this.onechange==true">OneChange</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;" *ngIf="this.usermgmt==true">Environment Details</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;" *ngIf="this.gitdetails==true">GIT Details</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;" *ngIf="this.flowPromote==true">Architect Flow Promote</div>
                <div style="font-size: 23px;padding-left: 16px;color: #1a3098;" *ngIf="this.packagereport==true">Package Report</div>
                <div class="user-actions">
                    <!-- <div class="notifications">
                        <img src="assets/icons/bell.svg" (click)="openNav()">
                    </div> -->
                    
                    <div class="user-profile" [class.active]="showUserMenu">
                        <div class="dropdown-trigger" (click)="userMenuToggler()">
                            <div class="thumb">
                                <img  src="/assets/dummy-profile-pic.jpg" style="width: 40px;height: 40px;border-radius: 50%;">
                            </div>
                            <div class="label" style="color: #1a3098;">{{user?.name}} {{user?.middle_name}}</div>
                        </div>
                        <div class="user-nav-list" *ngIf="showUserMenu">
                            <!--Hari Sir told me to remove thos uninstall on 15/02/24 -->
                            <!-- <div (click)="uninstall()">Uninstall</div> --> 
                            <div (click) = "navTo('/home/user-mangement')"> Environment Details</div>
                            <div (click) = "navTo('/home/git-details')"> GIT Details</div>
                            <!-- <div (click)="navTo('/home/settings/orders')">Order History</div> -->
                            <!-- <div (click)="navTo('/home/settings/password')">Change Password</div> --> 
                            <!-- <div (click)="navTo('/home/settings/Contactus')">Contact Us</div> -->
                            <!-- <div (click)="navTo('/home/settings/Paymentdetails')">Payment Details</div> -->
                            <!-- <div (click)="navTo('/home/settings/CountryDialing')" *ngIf="availableFeatures.LoadTesting||availableFeatures.ScheduledTesting">InCountry Dialling</div>
                            <div (click)="navTo('/home/settings/WhatsAppProfile')" *ngIf="availableFeatures.LoadTesting||availableFeatures.ScheduledTesting">WhatsApp Profile</div> -->
                            <!-- <div class="red" (click)="logout()">Logout</div> -->
                            <!-- <div class="red" (click)="uninstall()">Unistall</div> -->
                        </div>
                    </div>

                </div>
            </div>
            
            <div id="allMainPage" *ngIf="settingWidth" style="width: 80%;">
                 <router-outlet></router-outlet>
            </div>
            <div id="allMainPage" *ngIf="!settingWidth" style="width: 100%;">
                <router-outlet></router-outlet>
           </div>
            <div id="mySidenav" class="Notificationnav">
                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                <iframe src="https://quicktest.ai/getstarted/" name="targetframe" allowTransparency="true" scrolling="yes" frameborder="0"  height="100%">
                  </iframe>>
              </div>
        </div>
        
    </div>
</div>